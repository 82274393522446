// import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
  const { t } = useTranslation();
  // const [btnColor, setBtnColor] = useState('black');
  const protocolDataList1 = t('landingPage:privacy:protocol-data-list-1', {
    returnObjects: true,
  });
  const protocolDataList2 = t('landingPage:privacy:protocol-data-list-2', {
    returnObjects: true,
  });

  return (
    <div className="font-gordita">
      <div
        className="d-flex justify-content-center"
        style={{ margin: '20px', overflowWrap: 'break-word' }}
      >
        <div className="privacy-middle-section">
          <div className="title-style-six text-left m-auto">
            <h2>{t('landingPage:privacy:title')}</h2>
          </div>
          <div className="pt-20 pb-20">
            <p>{t('landingPage:privacy:title-description-1')}</p>
            <p className="pt-20">
              {t('landingPage:privacy:title-description-2')}
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:responsible')}</h3>
            <p className="pt-20">
              {t('landingPage:privacy:responsible-description')}
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:dpo')}</h3>
            <p className="pt-20">{t('landingPage:privacy:dpo-description')}</p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:data-and-purposes-of-processing')}</h3>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:protocol-data')}</h3>
            <p className="pt-20">
              {t('landingPage:privacy:protocol-data-description-1')}
              <b>{t('landingPage:privacy:protocol-data-description-2')}</b>.
            </p>
            <p className="pt-20">
              {t('landingPage:privacy:protocol-data-description-3')}
            </p>
            <div
              style={{
                width: 'fit-content',
                paddingLeft: '31px',
              }}
            >
              <ul
                className="pt-20"
                style={{
                  listStyleType: 'square',
                }}
              >
                {protocolDataList1.map((entry) => {
                  return <li key={entry}>{entry}</li>;
                })}
              </ul>
            </div>
            <p className="pt-20">
              {t('landingPage:privacy:protocol-data-description-4')}
            </p>
            <div
              style={{
                width: 'fit-content',
                paddingLeft: '31px',
              }}
            >
              <ul
                className="pt-20"
                style={{
                  listStyleType: 'square',
                }}
              >
                {protocolDataList2.map((entry) => {
                  return <li key={entry}>{entry}</li>;
                })}
              </ul>
            </div>
            <p className="pt-20">
              {t('landingPage:privacy:protocol-data-description-5')}
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:newsletter-regristration')}</h3>
            <p className="pt-20">
              {t('landingPage:privacy:newsletter-regristration-description-1')}
              <br />
              {t('landingPage:privacy:newsletter-regristration-description-2')}
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:cookies-title')}</h3>
            <p className="pt-20">
              {t('landingPage:privacy:cookies-description')}
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:matomo-title')}</h3>
            <p className="pt-20">
             {t('landingPage:privacy:matomo-description')}             
              <br />
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:external-links-title')}</h3>
            <p className="pt-20">
             {t('landingPage:privacy:external-links-description')}       
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:disclosure-of-data')}</h3>
            <p className="pt-20">
              {t('landingPage:privacy:disclosure-of-data-description')}
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:security-measures')}</h3>
            <p className="pt-20">
              {t('landingPage:privacy:security-measures-description-1')}
              <br />
              {t('landingPage:privacy:security-measures-description-2')}
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h3>{t('landingPage:privacy:the-rights-of-the-data-subject')}</h3>
            <p className="pt-20">
              {t(
                'landingPage:privacy:the-rights-of-the-data-subject-description-1'
              )}
              <br />
              {t(
                'landingPage:privacy:the-rights-of-the-data-subject-description-2'
              )}
              <br />
              {t(
                'landingPage:privacy:the-rights-of-the-data-subject-description-3'
              )}
              <br />
              {t(
                'landingPage:privacy:the-rights-of-the-data-subject-description-4'
              )}
            </p>
          </div>
          <div className="pt-20 pb-20">
            <p className="pt-20">
              {t('landingPage:privacy:the-rights-of-the-data-subject-version')}
            </p>
          </div>
          <div className="pt-20 pb-20">
            <h5>{t('landingPage:privacy:data-protection-notice')}</h5>
            <hr />
            <div className="d-flex flex-row">
              <div>
                <p>
                  {t(
                    'landingPage:privacy:data-protection-notice-description-1'
                  )}
                </p>
              </div>
              <div
                style={{
                  margin: 'auto',
                }}
              >
                <img
                  style={{
                    width: '45px',
                    cursor: 'pointer',
                  }}
                  src="images/icon/pdf.svg"
                  title="Datenschutz-Mitteilung im Sinne der Art. 13 und 14 der Datenschutz-Grundverordnung Nr. 679/2016"
                  alt="Datenschutz-Mitteilung im Sinne der Art. 13 und 14 der Datenschutz-Grundverordnung Nr. 679/2016"
                  onClick={() =>
                    window.open(
                      'https://konverto.eu/fileadmin/PDFs/Verbraucherinfos/Informationsmitteilung_Datenschutz_Kunden_DE_01.2021.pdf',
                      '_blank'
                    )
                  }
                />
              </div>
            </div>
            <hr />
          </div>
          <div className="d-flex pt-50">
            <div className="pr-3">
              <h5>{t('landingPage:privacy:mail')}</h5>
              <a href="mailto:info@konverto.eu">
                {t('landingPage:privacy:mail-description')}
              </a>
            </div>
            <div className="pr-3">
              <h5>{t('landingPage:privacy:phone')}</h5>
              <a href="tel:+390471064500">
                {t('landingPage:privacy:phone-description-1')}
              </a>
              <p>
                {t('landingPage:privacy:phone-description-2')}
                <a href="tel:+800 031 031">
                  {t('landingPage:privacy:phone-description-3')}
                </a>
              </p>
            </div>
            <div>
              <h5>{t('landingPage:privacy:address')}</h5>
              <p>{t('landingPage:privacy:address-description-1')}</p>
              <p>{t('landingPage:privacy:address-description-2')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
