import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import CounterTwo from '../../components/counter/CounterTwo';

const FancyTextBlock21 = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="row align-items-center">
        <div
          className="col-lg-6 col-md-7 m-auto"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="img-meta">
            <img src="images/assets/quote.svg" alt="media" className="m-auto" />
          </div>
        </div>
        {/* End .col */}

        {/* <div
          className="col-xl-5 col-lg-6 ml-auto"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="100"
        > */}
        {/* <div className="text-wrapper md-pt-50">
            <div className="client-info">{t('landingPage:data.title')}</div>
            <p>{t('landingPage:data.quote')}</p>
            <div className="name"> {t('landingPage:data.author')}</div>
          </div> */}
        <div className="col-xl-5">
          <CounterTwo />
          {/* TODO add citation */}
        </div>
        {/* /.text-wrapper */}
        {/* </div> */}
        {/* End .col */}
      </div>

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="FDaF8_5dzzk"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default FancyTextBlock21;
