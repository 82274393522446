import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useTranslation } from 'react-i18next';

const CounterTwo = () => {
  const [focus, setFocus] = React.useState(false);
  const { t } = useTranslation();

  const facts = t('landingPage:data.facts', { returnObjects: true });

  const animationDelays = [0, 100, 200];

  const easeOutCirc = (t, b, c, d) => {
    // return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
    return t === d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
    // return c * Math.sin((t / d) * (Math.PI / 2)) + b;
  };

  return (
    <div className="col justify-content-center">
      {facts.map((fact, i) => (
        <div
          // className="col-lg-4 col-sm-6"
          className="pb-50"
          // data-aos="fade-up"
          // data-aos-duration="1200"
          data-aos-delay={animationDelays[i]}
          key={i}
        >
          <div className="counter-box-four">
            <h2 className="number">
              <span className="timer">
                {' '}
                <CountUp
                  start={
                    focus
                      ? fact.description !== facts[2].description
                        ? fact.value * 0.99
                        : fact.value * 2
                      : null
                  }
                  end={fact.value}
                  useEasing={fact.description !== facts[2].description}
                  easingFn={easeOutCirc}
                  duration={4}
                  decimal={','}
                  decimals={fact.value % 1 !== 0 ? 1 : 0}
                  formattingFn={(n) => n?.toLocaleString()}
                  redraw={true}
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                    >
                      <span ref={countUpRef}></span>
                    </VisibilitySensor>
                  )}
                </CountUp>
              </span>
              &nbsp;
              {fact.unit}
            </h2>
            <p className="font-rubik">{fact.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CounterTwo;
