import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const HeaderPopupForm = () => {
  const { t } = useTranslation();
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('landingPage:contact-us.form.name-missing')),
    email: Yup.string()
      .required(t('landingPage:contact-us.form.email-missing'))
      .email(t('landingPage:contact-us.form.email-wrong-format')),
    sendMessage: Yup.string().required(
      t('landingPage:contact-us.form.message-missing')
    ),
    checkedCheckbox: Yup.boolean().oneOf(
      [true],
      t('landingPage:contact-us.form.checkbox-missing')
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data, e) {
    const email = t('landingPage:contact-us.email');
    const subject = `${t('landingPage:contact-us.subject', {
      customer: data.name,
    })}`;
    document.location = `mailto:${email}?subject=${subject}&body=${data.sendMessage}`;
    e.target.reset();
    reset();
  }

  return (
    <>
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="messages"></div>
        <div className="row controls">
          <div className="col-12">
            <div className="input-group-meta form-group mb-20">
              <label>{t('landingPage:contact-us.form.name')}</label>
              <input
                type="text"
                placeholder={t('landingPage:contact-us.form.name-placeholder')}
                name="name"
                {...register('name')}
                className={`${errors.name ? 'is-invalid' : ''}`}
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name?.message}</div>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="input-group-meta form-group mb-20">
              <label>{t('landingPage:contact-us.form.email')}</label>
              <input
                placeholder={t('landingPage:contact-us.form.email-placeholder')}
                name="email"
                type="text"
                {...register('email')}
                className={` ${errors.email ? 'is-invalid' : ''}`}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email?.message}</div>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="input-group-meta form-group mb-20">
              <label>{t('landingPage:contact-us.form.message')}</label>
              <textarea
                placeholder={t(
                  'landingPage:contact-us.form.message-placeholder'
                )}
                name="sendMessage"
                type="text"
                {...register('sendMessage')}
                className={`${errors.sendMessage ? 'is-invalid' : ''}`}
              ></textarea>
              {errors.sendMessage && (
                <div className="invalid-feedback">
                  {errors.sendMessage?.message}
                </div>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 mb-30">
            <div className="row">
              <div
                className="d-flex align-items-center"
                style={{
                  paddingLeft: '15px',
                  paddingRight: '15px',
                }}
              >
                <input
                  name="checkedCheckbox"
                  type="checkbox"
                  style={{
                    width: '26px',
                    height: '26px',
                  }}
                  {...register('checkedCheckbox')}
                  className={`${errors.checkedCheckbox ? 'is-invalid' : ''}`}
                />
                {errors.checkedCheckbox && (
                  <div
                    className="invalid-feedback"
                    style={{
                      left: 15,
                    }}
                  >
                    {errors.checkedCheckbox?.message}
                  </div>
                )}
              </div>
              <div className="col-10 pl-0 d-flex align-items-center">
                <p
                  style={{
                    fontSize: '12px',
                    lineHeight: '1rem',
                  }}
                >
                  {t('landingPage:contact-us.form.checkbox-part-1')}
                  <a href="/privacy">
                    <b>{t('landingPage:contact-us.form.checkbox-part-2')}</b>
                  </a>
                  {t('landingPage:contact-us.form.checkbox-part-3')}
                </p>
              </div>
            </div>
          </div>

          <div className="col-12">
            <button className="theme-btn-seven w-100">
              {t('landingPage:contact-us.form.submit')}
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>
    </>
  );
};

export default HeaderPopupForm;
