import React, { useState } from 'react';
import HeaderLanding from '../../components/header/landing/HeaderLanding';
import HeroBannerFive from '../../components/hero-banner/HeroBannerFive';
import FancyTextBlock21 from '../../components/fancy-text-block/FancyTextBlock21';
import FancyFeatureEleven from '../../components/features/FancyFeatureEleven';
import TestimonialFour from '../../components/testimonial/TestimonialFour';
import KultivasFooter from '../../components/footer/KultivasFooter';
import FancyFeatureTewentyFour from '../../components/features/FancyFeatureTewentyFour';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import HeaderPopupForm from '../../components/form/HeaderPopupForm';

const ProductLanding = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="main-page-wrapper font-gordita">
      {/* End Page SEO Content */}
      <HeaderLanding toggleModalOne={toggleModalOne} />
      {/* End HeaderLanding */}
      <div id="home">
        <HeroBannerFive />
      </div>
      {/* End HeaderBannerFive */}
      {/* 	=============================================
				Fancy Feature Ten
			==============================================  */}
      <div className="fancy-feature-ten pt-100 md-pt-70" id="features">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-md-12 pb-20"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="title-style-six">
                  <h4>{t('landingPage:features:title')}</h4>
                </div>
              </div>
              {/* <div
                className="col-md-6"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="title-style-six">
                  <h2>{t('landingPage:features:title')}</h2>
                </div> */}
              {/* /.title-style-six */}
              {/* </div> */}
              {/* End .col */}

              {/* <div
                className="col-lg-5 col-md-6"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <p className="sub-text pt-30 pb-30">
                  {t('landingPage:features:subtitle')}
                </p>
              </div> */}
              {/* End .col */}
            </div>
            <FancyFeatureTewentyFour />
            {/* End Fancy Feature Ten */}
          </div>
          {/* <img
            src="images/shape/137.svg"
            alt="shape"
            className="shapes shape-one"
          /> */}
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-feature-ten */}
      {/*   =============================================
				Fancy Text block Twenty One
			==============================================  */}
      <div className="fancy-text-block-twentyOne pt-170 md-pt-100" id="data">
        <div className="container">
          <FancyTextBlock21 />
        </div>
      </div>
      {/* /.fancy-text-block-twentyOne */}
      {/*  =====================================================
				Citattion Section
			===================================================== */}
      <div
        className="w-75 mx-auto"
        style={{
          borderTop: '1px solid #f1f1f3',
          borderBottom: '1px solid #f1f1f3',
          marginTop: '100px',
          paddingTop: '50px',
          paddingBottom: '50px',
        }}
      >
        <div className="img-meta p-4">
          <img
            src="images/assets/PeterWerth@2x.png"
            alt="media"
            className="m-auto"
            style={{
              minWidth: '50px',
              width: '100px',
              height: 'auto',
            }}
          />
        </div>
        <p
          className="text-break text-wrap font-rubik"
          style={{
            hyphens: 'auto',
            textAlign: 'center',
            color: 'black',
          }}
        >
          {t('landingPage:citation-PeterWerth:desc')}
        </p>
        <p
          style={{
            fontWeight: 500,
            fontSize: '18px',
            marginBlockStart: '1em',
            marginBlockEnd: '1em',
            textAlign: 'center',
          }}
        >
          {t('landingPage:citation-PeterWerth:author')},&nbsp;
          <span
            style={{
              fontWeight: 'normal',
              fontSize: '16px',
              color: '#73737b',
            }}
          >
            {t('landingPage:citation-PeterWerth:position')}
          </span>
        </p>
      </div>
      {/*   =====================================================
				Fancy Feature Eleven
			===================================================== */}
      <div className="fancy-feature-eleven pt-50 md-pt-80" id="products">
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-9 m-auto">
                <div className="title-style-six text-center">
                  <h2>{t('landingPage:products:subtitle')}</h2>
                  {/* <h2>{t('landingPage:products:title')}</h2> */}
                </div>
                {/* /.title-style-six */}
              </div>
            </div>
          </div>
          {/* End .container */}
          <FancyFeatureEleven />
        </div>
        {/* /.inner-container */}
        {/* <img
          src="images/shape/139.svg"
          alt="shape"
          className="shapes shape-one"
        /> */}
        {/* <img
          src="images/shape/140.svg"
          alt="shape"
          className="shapes shape-two"
        /> */}
      </div>
      {/* /.fancy-feature-eleven */}
      {/*  =====================================================
				Pricing Section Four
			===================================================== */}
      {/*<div className="pricing-section-four pt-200 md-pt-100" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-10  m-auto">
              <div className="title-style-six text-center">
                <h2>
                  Solo, Agency or Team? We’ve got you <span>covered.</span>
                </h2>
              </div>
            </div>
          </div>
        </div>

        <Pricing />
      </div>*/}
      {/*  /.pricing-section-four */}
      {/*  =====================================================
				Client Feedback Slider Four
			===================================================== */}
      <div
        className="client-feedback-slider-four mt-200 md-mt-100"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-9 col-md-8 m-auto">
                <div className="title-style-six text-center">
                  <h2>{t('landingPage:feedback:title')}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="clientSliderFour slick-nav-none">
            <TestimonialFour />
          </div>
          {/* <img
            src="images/shape/141.svg"
            alt="shape"
            className="shapes shape-one"
          /> */}
          {/* <img
            src="images/shape/142.svg"
            alt="shape"
            className="shapes shape-two"
          /> */}
        </div>
        {/* /.inner-container */}
      </div>
      {/* /.client-feedback-slider-four */}
      {/*  =====================================================
				Fancy Short Banner Six
			===================================================== */}
      <div className="fancy-short-banner-six mt-150 md-mt-80">
        <img
          src="images/shape/143.svg"
          alt="shape"
          className="shapes shape-one"
        />

        <div className="container">
          <div className="row">
            <div
              className="col-xl-9 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-six text-center">
                <h2>
                  {t('landingPage:demo-request:title')}
                  <br />
                </h2>
              </div>
              {/* /.title-style-six */}
            </div>
          </div>
          <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
            {t('landingPage:demo-request:subtitle')}
          </p>
          <button
            className="theme-btn-seven"
            onClick={toggleModalOne}
            style={{ margin: 'auto' }}
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <span>{t('landingPage:nav-bar.demo-request')}</span>
          </button>
        </div>

        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-six */}
      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer style={{ marginTop: '60px', marginBottom: '20px' }}>
        <div className="inner-container" style={{ verticalAlign: 'center' }}>
          <div className="container">
            <KultivasFooter />
          </div>
        </div>
        {/* /.inner-container */}
      </footer>
      {/*  /.theme-footer-five */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="box_inner ">
          <main className="main-body box_inner modal-content clearfix">
            <button className="close" onClick={toggleModalOne}>
              <img src="images/icon/close.svg" alt="close" />
            </button>
            {/* End close icon */}

            <div className="left-side" style={{ background: '#f1f1f3' }}>
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 m-auto">
                    <blockquote>{t('landingPage:contact-us.quote')}</blockquote>
                    {/* <span className="bio">
                      —{t('landingPage:contact-us.author')}
                    </span> */}
                  </div>
                </div>
                <img
                  src="images/assets/contact.svg"
                  alt=""
                  className="illustration mt-auto"
                />
              </div>
            </div>
            {/* /.left-side */}

            <div className="right-side">
              <h2 className="form-title pb-1">
                {t('landingPage:contact-us.title')}
              </h2>
              <HeaderPopupForm />
            </div>
            {/*  /.right-side */}
          </main>
          {/* /.main-body */}
        </div>
      </Modal>
      {/* End  Modal For Request a demo */}
    </div>
  );
};

export default ProductLanding;
