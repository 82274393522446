import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Routes from './router/Routes';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener('load', AOS.refresh);

  return (
    <>
      <Helmet>
        <title>KULTIVAS</title>
        <meta property="og:site_name" content="deski" />
        <meta
          property="og:url"
          content="https://themeforest.net/user/ib-themes"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="KULTIVAS" />
        <meta
          name="keywords"
          content="data science, big data, ML, agriculture, climate, visualisation, saas"
        />
        <meta name="description" content="KULTIVAS" />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
