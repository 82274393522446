import React from 'react';

import ProductLanding from '../views/all-home-pages/ProductLanding';
import NotFound from '../views/NotFound';

// Route Specific
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollTopBehaviour from '../components/ScrollTopBehaviour';
import Privacy from '../views/all-home-pages/Privacy';
import Impressum from '../views/all-home-pages/Impressum';

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={ProductLanding} />
          {/*<Route
              path="/product-customer-support"
              component={ProductCustomerSupport}
          /> */}
          <Route path="/404" component={NotFound} />

          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/impressum" component={Impressum} />

          {/* NotFound Route */}
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
