import React from 'react';
import { useTranslation } from 'react-i18next';

const FancyFeatureEleven = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="block-style-sixteen"
        style={{ background: '#e7f9e8', marginTop: '50px' }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: '#28c832' }}
              >
                <img src="images/icon/ic_atlas.svg" alt="icon" />
              </div>
              <h3 className="title">{t('landingPage:products:atlas.title')}</h3>
              <p>{t('landingPage:products:atlas.description')}</p>
              {/* TODO: Add link to product
              <Link to="/solution-management">
                <img src="images/icon/72.svg" alt="icon" />
              </Link>
              */}
            </div>
          </div>
        </div>
        <img
          src="images/assets/atlas.png"
          alt=""
          className="shapes screen-one"
        />
      </div>
      {/* /.block-style-sixteen */}

      {/* <div className="block-style-sixteen" style={{ background: '#f0f5fb' }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: '#4285c7' }}
              >
                <img src="images/icon/ic_window.svg" alt="icon" />
              </div>
              <h3 className="title">
                {t('landingPage:products:window.title')}
                <h5 style={{ color: '#4285c7' }}>
                  {t('landingPage:comming-soon')}
                </h5>
              </h3>
              <p>{t('landingPage:products:window.description')}</p> */}
      {/* TODO: Add link to product
              <Link to="/solution-management">
                <img src="images/icon/72.svg" alt="icon" />
              </Link>
              */}
      {/* </div>
          </div>
        </div>
        <img
          src="images/assets/window.jpg"
          alt="screen"
          className="shapes screen-two"
        />
      </div> */}
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: '#fbf2ed' }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: '#da8151' }}
              >
                <img src="images/icon/ic_prisma.svg" alt="icon" />
              </div>
              <h3 className="title">
                {t('landingPage:products:birds-eye.title')}
              </h3>
              <p>{t('landingPage:products:birds-eye.description')}</p>
              {/* TODO: Add link to product */}
              {/* <Link to="/solution-management">
                <img src="images/icon/72.svg" alt="icon" />
              </Link> */}
            </div>
          </div>
        </div>
        <img
          src="images/assets/prisma.jpg"
          alt="screen"
          className="shapes screen-three"
        />
      </div>
      {/*  /.block-style-sixteen */}
    </>
  );
};

export default FancyFeatureEleven;
