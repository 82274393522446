import { useTranslation } from 'react-i18next';
import React from 'react';

const contactAddresses = [
  'tel:800031031',
  'mailto:info@konverto.eu',
  'https://www.konverto.eu',
];

const KultivasLogo = 'images/logo/kultivas.svg';

export default function KultivasFooter() {
  const { t } = useTranslation();

  const contactAddressLabels = t('landingPage:footer.contact-addresses', {
    returnObjects: true,
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={KultivasLogo}
        alt="KultivasIcon"
        style={{ width: '170px', height: 'auto', paddingRight: '15px' }}
      />
      <div style={{ borderLeft: '1px solid grey', height: '95px' }} />
      <div style={{ fontSize: '9px', paddingLeft: '15px' }}>
        <div>
          <p style={{ fontSize: '14px' }}>{t('landingPage:footer.credits')}</p>
        </div>
        <div>
          <p style={{ fontSize: '14px', display: 'inline' }}>
            {t('landingPage:footer.info-center')}{' '}
          </p>
          {contactAddressLabels.map((contactAdressLabel, index) => {
            if (index + 1 === contactAddressLabels.length) {
              return (
                <a
                  key={contactAddresses[index]}
                  href={contactAddresses[index]}
                  color="black"
                  style={{ fontSize: '14px' }}
                >
                  {contactAdressLabel.contact}
                </a>
              );
            } else {
              return (
                <div
                  key={contactAddresses[index]}
                  style={{ display: 'inline' }}
                >
                  <a
                    href={contactAddresses[index]}
                    color="black"
                    style={{ fontSize: '14px', padding: '0px 2px' }}
                  >
                    {contactAdressLabel.contact}
                  </a>
                  <span key={index} style={{ fontSize: '14px' }}>
                    {' '}
                    |{' '}
                  </span>
                </div>
              );
            }
          })}
        </div>
        <div style={{ display: 'inline', fontSize: '14px' }}>
          <a href={'/privacy'}>{t('landingPage:footer.privacy')}</a>
        </div>
        <div style={{ display: 'inline', fontSize: '14px' }}>
          <span> | </span>
        </div>
        <div style={{ display: 'inline', fontSize: '14px' }}>
          <a href={'/impressum'}>{t('landingPage:footer.impressum')}</a>
        </div>
      </div>
    </div>
  );
}
