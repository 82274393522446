import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {useTranslation} from "react-i18next";

const NotFound = () => {
  const logo = 'images/logo/kultivas.svg';
  const { t } = useTranslation();

  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>KULTIVAS</title>
      </Helmet>
      {/* End Page SEO Content */}

      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img
            src="images/assets/error.svg"
            alt="media"
            className="w-100 illustration"
          />
        </div>
        <div className="text-wrapper order-lg-first">
          <div className="logo">
            <Link to="/">
              <img src={logo} width="170px" height="auto" alt="logo" />
            </Link>
          </div>
          <h1 className="font-slab">
            {t('landingPage:not-found.title')}
          </h1>
          <p className="font-rubik">
            {t('landingPage:not-found.subtitle')}
          </p>

          <Link
            to="/"
            className="back-home font-rubik d-flex align-items-center justify-content-center"
          >
            <span>{t('landingPage:not-found.back-to-home')}</span>
            <img src="images/icon/53.svg" alt="icon" />
          </Link>
        </div>
        {/* /.text-wrapper */}
      </div>
      {/* /.error-page */}
    </div>
  );
};

export default NotFound;
