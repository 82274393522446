import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import deLandingPage from './locales/de/landingPage.json';
import itLandingPage from './locales/it/landingPage.json';
import enLandingPage from './locales/en/landingPage.json';

export const Languages = ['de', 'it', 'en'];

const Namespaces = ['landingPage'];

const resources = {
  de: {
    landingPage: deLandingPage,
  },
  it: {
    landingPage: itLandingPage,
  },
  en: {
    landingPage: enLandingPage,
  },
};

const defaultBrowserLanguage = navigator.language || navigator.userLanguage;
if (!localStorage.getItem('i18nextLng')) {
  if (Object.keys(resources).includes(defaultBrowserLanguage.split('-')[0])) {
    localStorage.setItem('i18nextLng', defaultBrowserLanguage.split('-')[0]);
  } else {
    localStorage.setItem('i18nextLng', 'en');
  }
}

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  supportedLngs: Languages,
  fallbackLng: 'en',
  ns: Namespaces,
  defaultNS: 'landingPage',
});

i18n.languages = Languages;

// Add custom formatters
// See: https://www.i18next.com/translation-function/formatting
i18n.services.formatter?.add('lowercase', (value, _lng, _options) =>
  value.toLowerCase()
);

export default i18n;
