import React, { useState } from 'react';
import Scrollspy from 'react-scrollspy';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { Languages } from '../../../translation/config';

Modal.setAppElement('#root');

const logo = 'images/logo/kultivas.svg';

const HeaderLanding = (props) => {
  const { t, i18n } = useTranslation();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);
  const Webapps = t('landingPage:login-links', { returnObjects: true });

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  window.addEventListener('scroll', changeBackground);

  const [loginListOpen, setLoginListOpen] = useState('none');
  const [languageListOpen, setLanguageListOpen] = useState('none');

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? 'theme-main-menu sticky-menu theme-menu-five fixed'
            : 'theme-main-menu sticky-menu theme-menu-five'
        }
      >
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="brand" width="170px" height="auto" />
            </Link>
          </div>
          {/* End logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? 'active' : ''}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={['features', 'data', 'products', 'feedback']}
                    currentClassName="active"
                    offset={-500}
                  >
                    <li className="nav-item">
                      <a href="#features" className="nav-link">
                        {t('landingPage:nav-bar.features')}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#data" className="nav-link">
                        {t('landingPage:nav-bar.data')}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#products" className="nav-link">
                        {t('landingPage:nav-bar.products')}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#feedback" className="nav-link">
                        {t('landingPage:nav-bar.feedback')}
                      </a>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/#"
                        data-toggle="dropdown"
                      >
                        {t('landingPage:nav-bar.login')}
                      </a>
                      <ul className="dropdown-menu">
                        {Webapps.map((val, i) => (
                          <li key={i}>
                            <a
                              href={val.routerPath}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdown-item"
                            >
                              {val.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                      {/* /.dropdown-menu */}
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/#"
                        data-toggle="dropdown"
                      >
                        {i18n.language.toUpperCase()}
                      </a>
                      <ul className="dropdown-menu">
                        {Languages.map((lang, i) => (
                          <li key={i}>
                            <button
                              onClick={() => handleLanguageChange(lang)}
                              className="dropdown-item"
                            >
                              {i18n.language === lang ? (
                                <b>{lang.toUpperCase()}</b>
                              ) : (
                                <>{lang.toUpperCase()}</>
                              )}
                            </button>
                          </li>
                        ))}
                      </ul>
                      {/* /.dropdown-menu */}
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          <div className="right-widget">
            <button className="demo-button" onClick={props.toggleModalOne}>
              <span>{t('landingPage:nav-bar.contact-request')}</span>
              <img src="images/icon/user.svg" alt="icon" />
            </button>
          </div>
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? 'mobile-menu  menu-open' : 'mobile-menu'}>
        <div className="logo order-md-1">
          <Link to="/">
            <img src={logo} alt="brand" width="138px" height="auto" />
          </Link>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close.svg" alt="icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav"
          id="theme-menu-list"
          items={['features', 'data', 'products', 'feedback']}
          currentClassName="active"
          offset={-200}
        >
          <li className="nav-item">
            <a href="#features" className="nav-link" onClick={handleClick}>
              {t('landingPage:nav-bar.features')}
            </a>
          </li>
          <li className="nav-item">
            <a href="#data" className="nav-link" onClick={handleClick}>
              {t('landingPage:nav-bar.data')}
            </a>
          </li>
          <li className="nav-item">
            <a href="#products" className="nav-link" onClick={handleClick}>
              {t('landingPage:nav-bar.products')}
            </a>
          </li>
          <li className="nav-item">
            <a href="#feedback" className="nav-link" onClick={handleClick}>
              {t('landingPage:nav-bar.feedback')}
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/#"
              data-toggle="dropdown"
              onClick={() =>
                setLoginListOpen(loginListOpen === 'none' ? 'block' : 'none')
              }
            >
              {t('landingPage:nav-bar.login')}
            </a>
            <ul
              // className="dropdown-menu"
              id="LoginList"
              style={{
                display: loginListOpen,
              }}
            >
              {Webapps.map((val, i) => (
                <li key={i}>
                  <a
                    href={val.routerPath}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dropdown-item"
                  >
                    {val.name}
                  </a>
                </li>
              ))}
            </ul>
            {/* /.dropdown-menu */}
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="/#"
              data-toggle="dropdown"
              onClick={() =>
                setLanguageListOpen(
                  languageListOpen === 'none' ? 'inline-block' : 'none'
                )
              }
            >
              {i18n.language.toUpperCase()}
            </a>
            <ul
              // className="dropdown-menu"
              style={{
                display: languageListOpen,
              }}
            >
              {Languages.map((lang, i) => (
                <li key={i}>
                  <button
                    onClick={() => handleLanguageChange(lang)}
                    className="dropdown-item"
                  >
                    {i18n.language === lang ? (
                      <b>{lang.toUpperCase()}</b>
                    ) : (
                      <>{lang.toUpperCase()}</>
                    )}
                  </button>
                </li>
              ))}
            </ul>
            {/* /.dropdown-menu */}
          </li>
        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
    </>
  );
};

export default HeaderLanding;
