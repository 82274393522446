import React from 'react';
import { useTranslation } from 'react-i18next';

const FancyFeatureTewentyFour = () => {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: '#001E05' }}
            >
              <img src="images/icon/ic_features_klimadaten.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>{t('landingPage:features.feature-1.title')}</h4>
              <p>{t('landingPage:features.feature-1.description')}</p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: '#003214' }}
            >
              <img src="images/icon/ic_features_sonne.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>{t('landingPage:features.feature-2.title')}</h4>
              <p>{t('landingPage:features.feature-2.description')}</p>
            </div>
          </div>
        </div>{' '}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: '#005A32' }}
            >
              <img src="images/icon/ic_features_gdd.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>{t('landingPage:features.feature-3.title')}</h4>
              <p>{t('landingPage:features.feature-3.description')}</p>
            </div>
          </div>
          <div
            className="icon d-flex"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
            style={{
              position: 'absolute',
              top: '-45px',
              left: '7px',
            }}
          >
            <img
              src="images/kultibot/Kulti_a.svg"
              alt="kultibot-vers-a"
              style={{
                width: '110px',
                height: 'auto',
              }}
            />
          </div>
        </div>{' '}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: '#008232' }}
            >
              <img src="images/icon/ic_feature_karten.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>{t('landingPage:features.feature-4.title')}</h4>
              <p>{t('landingPage:features.feature-4.description')}</p>
            </div>
          </div>
        </div>{' '}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: '#00AA32' }}
            >
              <img
                src="images/icon/ic_features_lagen-standorte.svg"
                alt="icon"
              />
            </div>
            <div className="text">
              <h4>{t('landingPage:features.feature-5.title')}</h4>
              <p>{t('landingPage:features.feature-5.description')}</p>
            </div>
          </div>
        </div>{' '}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: '#28C832' }}
            >
              <img src="images/icon/ic_features_orbit.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>{t('landingPage:features.feature-6.title')}</h4>
              <p>{t('landingPage:features.feature-6.description')}</p>
            </div>
          </div>
        </div>{' '}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: '#3BE646' }}
            >
              <img src="images/icon/ic_features_vorhersage.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>{t('landingPage:features.feature-7.title')}</h4>
              <p>{t('landingPage:features.feature-7.description')}</p>
            </div>
          </div>
        </div>{' '}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: '#876D40' }}
            >
              <img
                src="images/icon/ic_features_kulturpflanzen.svg"
                alt="icon"
              />
            </div>
            <div className="text">
              <h4>{t('landingPage:features.feature-8.title')}</h4>
              <p>{t('landingPage:features.feature-8.description')}</p>
            </div>
          </div>
        </div>{' '}
        {/* /.block-style-twentyFour */}
      </div>

      {/* End .col */}
    </div>
  );
};

export default FancyFeatureTewentyFour;
