import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';

export default function TestimonialFour() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const { t, ready } = useTranslation();

  if (!ready) return 'loading translations...';

  const feedbacks = t('landingPage:feedback.feedbacks', {
    returnObjects: true,
  });

  return (
    <Slider {...settings}>
      {feedbacks.map((val, i) => (
        <div className="item" key={i}>
          <div className="feedback-wrapper">
            {/* <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // minHeight: '500px',
              }}
            > */}
            <img
              src={`${val.img}`}
              alt="icon"
              className="icon"
              style={{
                width: '100px',
                height: 'auto',
              }}
            />
            <p>{val.desc}</p>
            <div className="d-sm-flex justify-content-between align-items-center">
              <h6 className="name">
                {val.author},&nbsp;
                <span>{val.position}</span>
              </h6>
            </div>
            {/* </div> */}
          </div>
        </div>
      ))}
    </Slider>
  );
}
