import { useTranslation } from 'react-i18next';

const Impressum = () => {
  const { t } = useTranslation();
  const section1 = t('landingPage:imprint:section-1', {
    returnObjects: true,
  });
  const section2 = t('landingPage:imprint:section-2', {
    returnObjects: true,
  });
  const section3 = t('landingPage:imprint:section-3', {
    returnObjects: true,
  });
  const section4 = t('landingPage:imprint:section-4', {
    returnObjects: true,
  });
  return (
    <div className="font-gordita">
      <div
        className="d-flex justify-content-center"
        style={{ margin: '20px', overflowWrap: 'break-word' }}
      >
        <div className="privacy-middle-section">
          <div className="title-style-six text-left m-auto">
            <h2>{t('landingPage:imprint:title')}</h2>
          </div>
          <div className="d-flex pt-30">
            <h4
              style={{
                textTransform: 'uppercase',
              }}
            >
              {t('landingPage:imprint:subtitle-1')}
            </h4>
          </div>
          {section1.map((entry) => {
            return (
              <div className="pt-5">
                <p>{entry}</p>
              </div>
            );
          })}
          <div className="pt-30">
            <div className="pt-5">
              <h4>{t('landingPage:imprint:subtitle-2')}</h4>
            </div>
            {section2.map((entry) => {
              return (
                <div className="pt-5">
                  <p>{entry}</p>
                </div>
              );
            })}
          </div>
          <div className="pt-30">
            <div className="pt-5">
              <h4>{t('landingPage:imprint:subtitle-3')}</h4>
            </div>
            {section3.map((entry) => {
              return (
                <div className="pt-5">
                  <p>{entry}</p>
                </div>
              );
            })}
            <div className="pt-15">
              <h4>{t('landingPage:imprint:subtitle-4')}</h4>
            </div>
            {section4.map((entry) => {
              return (
                <div className="pt-5">
                  <p>{entry}</p>
                </div>
              );
            })}
            <div className="d-flex pt-50">
              <div className="pr-3">
                <h5>{t('landingPage:imprint:mail')}</h5>
                <a href="mailto:info@konverto.eu">
                  {t('landingPage:imprint:mail-description')}
                </a>
              </div>
              <div className="pr-3">
                <h5>{t('landingPage:imprint:phone')}</h5>
                <a href="tel:+390471064500">
                  {t('landingPage:imprint:phone-description-1')}
                </a>
                <p>
                  {t('landingPage:imprint:phone-description-2')}
                  <a href="tel:+800 031 031">
                    {t('landingPage:imprint:phone-description-3')}
                  </a>
                </p>
              </div>
              <div>
                <h5>{t('landingPage:imprint:address')}</h5>
                <p>{t('landingPage:imprint:address-description-1')}</p>
                <p>{t('landingPage:imprint:address-description-2')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
